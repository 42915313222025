import translate from '../data/translations';

export const mailExistProviderError = ({ provider }) => {
  switch (provider) {
    case 'facebook':
      return translate('error.mail.exist.provider.facebook', 'en');
    case 'google':
      return translate('error.mail.exist.provider.google', 'en');
    default:
      return translate('error.mail.exist.provider.local', 'en');
  }
};

export const premiumValidation = ({ isPremium, plan }) => {
  if (isPremium) {
    if (plan) {
      if (plan.currentProWidgets < plan.proWidgets || plan.proWidgets === -1) {
        return true;
      } else {
        throw {
          message: `Looks like you already used all your premium widgets. You’ll need to upgrade your plan to install it or`
        };
      }
    } else {
      throw {
        message: `Hey! This is a premium template, You’ll need to upgrade your plan to install it or`
      };
    }
  } else return true;
};

export const isPaidWidgetPlan = plan =>
  !!(plan && (plan.category === 'widget' || plan.type === 'appsumo'));
