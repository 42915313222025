import axios from 'axios';
import { getAuthHeader } from './security.service';

const api = axios.create();

declare global {
  interface Window {
    Woxo: {
      // sic (typo)
      Suscription: {
        plan: string | false;
      };
    };
  }
}

api.interceptors.request.use(async config => {
  const authHeaders = await getAuthHeader();
  if (authHeaders) {
    config.headers = {
      ...authHeaders,
      ...config.headers
    };
  }
  return config;
});

export default api;
