import axios from 'axios';
import { cleanupUserData } from '../security.service';

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error &&
      error.response &&
      error.response.status === 403 &&
      error.response.statusText === 'Forbidden'
    ) {
      cleanupUserData();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axios;
