import React from 'react';
import PropsTypes from 'prop-types';
import { withRouter } from 'next/router';
import Link from 'next/link';

const ActiveLink = ({
  router,
  children,
  className,
  href,
  activeClassName,
  onMouseEnter,
  onMouseLeave,
  target,
  ...props
}) => {
  let cls = className;
  if (router.pathname === href && activeClassName) cls += ` ${activeClassName}`;
  cls = cls ? cls.trim() : null;
  const dataProps = {};
  Object.keys(props).map(p => {
    if (p.startsWith('data-')) dataProps[p] = props[p];
    return p;
  });
  return (
    <Link {...props} href={href}>
      <a
        {...dataProps}
        className={cls}
        target={target}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </a>
    </Link>
  );
};
ActiveLink.propTypes = {
  router: PropsTypes.any,
  children: PropsTypes.oneOfType([PropsTypes.node, PropsTypes.arrayOf(PropsTypes.node)]),
  className: PropsTypes.string,
  target: PropsTypes.string,
  href: PropsTypes.string.isRequired,
  activeClassName: PropsTypes.string,
  onMouseEnter: PropsTypes.func,
  onMouseLeave: PropsTypes.func
};
ActiveLink.defaultProps = {
  router: {},
  children: null,
  className: null,
  target: '_self',
  activeClassName: null,
  onMouseEnter: () => {},
  onMouseLeave: () => {}
};

export default withRouter(ActiveLink);
