import { createContext, useState } from 'react';

//Al videos and their titles
const stepList = [
  {
    label: 'An intro to Batch Video Creation',
    url: 'https://youtu.be/eIYN0mA8xgs'
  },
  {
    label: '1. Video ideas and templates',
    url: 'https://youtu.be/eqLK0L1zDjg'
  },
  {
    label: '2. Edit captions, images & video clips',
    url: 'https://youtu.be/shaEQUPWWvQ'
  },
  {
    label: '3. Bulk customization',
    url: 'https://youtu.be/4jA6-WsNuZo'
  },
  {
    label: '4. Download & Share All',
    url: 'https://youtu.be/1SLlDCvty78'
  },
  {
    label: '5. Google Sheet to Videos',
    url: 'https://youtu.be/j4WSn8ON_Cc'
  }
];

const initializedVideoList = stepList.map(item => ({ ...item, completed: false }));

export const TutorialState = createContext({ currentStep: 0, videoList: initializedVideoList });

const TutorialStateProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [videoList, setVideoList] = useState(initializedVideoList);

  return (
    <TutorialState.Provider value={{ currentStep, setCurrentStep, videoList, setVideoList }}>
      {children}
    </TutorialState.Provider>
  );
};

export default TutorialStateProvider;
